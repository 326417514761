import React, {useState} from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinkWrapper,
    FooterLinkItems,
    FooterTitleWrapper,
    FooterLinkBtn,
    FooterCS,
    FooterLinkTitle,
    FooterLinkContents,
    FooterLinkBox,
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    SocialIconLink,
    WebsiteRights
}from './FooterElementes'
import ModalDom from '../Modal/Terms_ConditionsModal';

import TermsConditionsModal from '../TermsConditions'

const Footer = () => {
    const toggleHome = () =>{
        scroll.scrollToTop();
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
      setIsOpen(!isOpen);
    };
    const CloseModal = () => { 
        setIsOpen(false)
    }
    
  return (
      <FooterContainer>
          <FooterWrap>
              <FooterLinksContainer>
                  <FooterLinkWrapper>
                      <FooterLinkItems>
                        <FooterTitleWrapper>
                            <FooterLinkTitle>사업자 정보</FooterLinkTitle>
                            <FooterLinkBtn onClick={toggleModal}>이용약관</FooterLinkBtn>
                            <FooterCS>고객센터: zaduprint@gmail.com</FooterCS>
                            <FooterCS>대표 연락처 : 070-8080-0309</FooterCS>
                            {isOpen && (
                                <ModalDom
                                    CloseModal={() => {
                                    CloseModal();
                                }}>
                                    <TermsConditionsModal/>
                                </ModalDom>
                            )}
                        </FooterTitleWrapper>
                        <FooterLinkContents>
                            <FooterLinkBox>
                                <FooterLink>사업자 등록번호 : 850-01-03414 |</FooterLink>
                                <FooterLink>상호 : 자두(ZADU) |</FooterLink>
                                <FooterLink>대표 : 조준영 |</FooterLink>
                                <FooterLink>사업장 소재지 : 서울특별시 영등포구 국회대로 36길 6-1, 2층-J359호(당산동3가) |</FooterLink>
                                <FooterLink>개인정보관리책임자 : 조준영</FooterLink>
                            </FooterLinkBox>
                            <FooterLinkBox>
                                <FooterLink>통신판매업 : 2025-서울영등포-0222</FooterLink>
                            </FooterLinkBox>
                        </FooterLinkContents>
                      </FooterLinkItems>
                  </FooterLinkWrapper>
              </FooterLinksContainer>
              <SocialMedia>
                  <SocialMediaWrap>
                      <SocialLogo to ='/' onClick={toggleHome}>
                        ZADU
                      </SocialLogo>
                      <WebsiteRights>Copyright © {new Date().getFullYear()}ZADU Inc. All right reserved.</WebsiteRights>
                      <SocialIcons>
                          {/* <SocialIconLink href='/' target='_blank' aria-label = "Facebook">
                              <FaFacebook />
                          </SocialIconLink> */}
                          <SocialIconLink href='https://instagram.com/tookdak_official?igshid=MzRlODBiNWFlZA==' target='_blank' aria-label = "Instagram">
                              <FaInstagram />
                          </SocialIconLink>
                          {/* <SocialIconLink href='/' target='_blank' aria-label = "Youtube">
                              <FaYoutube />
                          </SocialIconLink> */}
                      </SocialIcons>
                  </SocialMediaWrap>
              </SocialMedia>
          </FooterWrap>
      </FooterContainer>
  )
}

export default Footer