import styled from "styled-components"
import {Link} from 'react-router-dom'

export const FooterContainer = styled.footer`
    background-color : #101522;
    margin: 0 auto;
    max-height: 400px;
    min-width: 1400px;
`

export const FooterWrap = styled.div`
    padding : 48px 24px;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    max-width : 1100px;
    max-height: 400px;
    margin : 0 auto;
`

export const FooterLinksContainer=styled.div`
    display : flex;
    justify-content : center;

    @media screen and (max-width : 820px){
        padding-top : 32px;
    }
`

export const FooterLinkWrapper = styled.div`
    display : flex;
    
    @media screen and (max-width : 1000px){
        flex-direction : column;
    }
`

export const FooterLinkItems = styled.div`
    display : flex;
    flex-direction : column;
    align-items : flex-start;
    margin : 16px;
    text-align : left;
    width : fit-content;
    height: fit-content;
    box-sizing : border-box;
    color : #fff;

    @media screen and (max-width : 420px){
        margin : 0;
        padding : 10px;
        width : 100%;
    }
`
export const FooterTitleWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;


    gap : 15px;

    margin-bottom : 16px;

`;

export const FooterLinkBtn = styled.div`
    width: fit-content;
    height: fit-content;

    align-items: baseline;
    text-align: end;
    font-size : 13px;

    cursor: pointer;

    &:hover{
        color : #fa7f25;
        transition : 0.3s ease-out;
    }

`;

export const FooterCS = styled.div`
    width: fit-content;
    height: fit-content;

    align-items: baseline;
    text-align: end;
    font-size : 13px;
`;

export const FooterLinkTitle = styled.h1`
    font-size : 18px;
    width: fit-content;
    height: fit-content;
`

export const FooterLinkContents = styled.div`
    display : flex;
    flex-direction : column;
    gap:3px;

`;

export const FooterLinkBox = styled.div`
    display : flex;
    flex-direction : row;
    gap: 3px;
    @media screen and (max-width : 1000px){
        flex-direction : column;
    }
`;

export const FooterLink =  styled.div`
    display: inline;
    white-space: nowrap;
    color : #fff;
    text-decoration : none;
    margin-bottom : 0.5rem;
    font-size : 13px;
    width: fit-content;

    /* &:hover{
        color : #fa7f25;
        transition : 0.3s ease-out;
    } */
`
export const SocialMedia = styled.section`
    max-width : 1000px;
    width : 100%;

`
export const SocialMediaWrap = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
    max-width : 1100px;
    margin : 40px auto 0 auto;

    @media screen and (max-width : 820px){
        flex-direction : column;
    }
`

export const SocialLogo = styled(Link)`
    color : #fa7f25;
    justify-self : start;
    cursor : pointer;
    text-decoration : none;
    font-size : 1.5rem;
    display : flex;
    align-items : center;
    margin-bottom : 16px;
    font-weight : bold;

    &:hover{
        color : #fff;
        transition : 0.3s ease-out;
    }
`

export const WebsiteRights = styled.small`
    color : #fff;
    margin-bottom : 16px;

`

export const SocialIcons = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
    width : fit-content;
`
export const SocialIconLink = styled.a`
    color : #fff;
    font-size : 24px;
`
